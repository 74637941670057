.react-draggable-maximized-hack {
  height: calc(100% - 29px) !important;
  width: calc(100% - 1px) !important;
  top: -2px !important;
  left: -2px !important;
  transform: none !important;
}

@media screen and (max-width: 567px) {
  .react-draggable-maximized-hack {
    height: calc(100% - 58px) !important;
  }
}

.react-draggable {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 7;
  /* transition: 500ms height ease-in, 500ms width ease-in; */
}
.react-resizable-handle {
  height: 15px;
  width: 15px;
  z-index: 1;
  position: absolute;
  right: -3px;
  bottom: -3px;
}

.WindowExplorer {
  width: 100%;
  height: 100%;

  // &.Window--maximized {
  //   width: calc(100% + 4px);
  //   height: calc(100% + 4px);
  // }
  &:not(.Window--active) {
    filter: grayscale(100%);
  }
}

.Window {
  &:not(.Window--active) .Window__heading {
    background: none;
    background-color: #808088;
  }
}
