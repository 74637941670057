@font-face {
  font-family: AMIBios;
  src: url("./assets/ami_bios1.woff2") format("woff2");
}

body {
  height: 100%;
  width: 100%;
  padding: 0;
  overflow: hidden;
  margin: 0;
  background-color: darkslategrey;
}

#root {
  display: flex;
  align-content: center;
  align-items: center;
}

.screen,
.w98.desktop {
  width: 100vw;
  height: 80vh;
}

.w98 .WindowExplorer .window__menu {
  padding: 2px 0px 2px 12px;
  align-items: center;
}

.smallText {
  font-size: 8px !important;
}

@media (min-width: 640px) and (min-height: 500px) {
  #root {
    height: 100vh !important;
  }
}

.desktop.desktopX2 {
  transform: scale(2);
}

.desktop.desktopX1_5 {
  transform: scale(1.5);
}

.desktop.notMobile {
  width: 640px;
  height: 480px;
}

.desktop.fullScreen {
  height: 100vh;
  width: 100vw;
  transform: scale(1);
}

.react-draggable > .Window {
  height: 100%;
  width: 100%;
}

.StandardMenuItem__button {
  padding-top: 1px;
}

.TaskBar {
  z-index: 75;
}

.itIsNowSafeToTurnOffYourComputer {
  background-color: #000000 !important;
  display: flex;
}
.itIsNowSafeToTurnOffYourComputer:after {
  content: "It's now safe to turn off your computer.";
  padding: 16px;
  text-align: center;
  color: orange;
  margin: auto;
  font-size: 22px;
  font-family: AMIBios, "Courier New", Courier, monospace;
}
.desktop.windowsShuttingDown {
  background-color: #a6c7df;
  background-image: url("./assets/windowsShuttingDown-min.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (min-width: 640px) and (min-height: 480px) {
  .windowsShuttingDown {
    background-size: cover;
  }
}

@media (max-height: 500px) {
  .hideMobileY {
    display: none !important;
  }
}
@media (max-width: 600px) {
  .hideMobileX {
    display: none !important;
  }
}

@media (max-width: 576px) {
  body {
    display: flex !important;
  }

  html {
    height: 100% !important;
  }

  .ExplorerView {
    flex-wrap: wrap !important;
    flex-flow: row wrap !important;
  }

  .icon.ExplorerIcon {
    flex: 25% !important;
    margin-top: 30px !important;
    height: 100px !important;
  }

  .icon.ExplorerIcon .icon__icon {
    width: 60px !important;
    height: 60px !important;
  }

  .icon.ExplorerIcon .icon__text {
    position: unset !important;
    font-size: 14px !important;
    margin-top: 20px !important;
  }

  .TaskBar {
    height: 50px !important;
  }

  .TaskBar > div,
  .TaskBar > button {
    height: 50px !important;
  }

  .btn.StartButton {
    height: 50px !important;
    width: 80px !important;
    background-size: auto 27px !important;
    background-position: 1px 10px !important;
  }

  .TaskBar__notifications {
    font-size: 18px !important;
    width: 50px !important;
  }

  .TaskBar__notifications__time {
    margin-left: 10px !important;
  }

  .TaskBar__quick-launch {
    display: none !important;
  }

  .desktop.fullScreen {
    height: 100% !important;
  }

  .TaskBar__start > button + div {
    bottom: 58px !important;
  }

  .btn.ButtonProgram {
    padding-left: 29px !important;
    height: 50px !important;
    background-position: 5px 15px !important;
    background-size: 20px !important;
    padding-left: 27px !important;
  }

  .Window__minimize,
  .Window__restore,
  .Window__close {
    width: 25px !important;
    height: 25px !important;
    background-position: center !important;
    background-size: contain !important;
  }

  .smallText {
    font-size: 6px !important;
  }
}
